import sources from "../config/sources"
import routes from '../navigation/routes';

export class Util {
  static parseWorkType (element) {
    if (element === 1) { return 'Animal care' }
    if (element === 2) { return 'Art project' }
    if (element === 3) { return 'Babysitting / Childcare' }
    if (element === 4) { return 'Building' }
    if (element === 5) { return 'Charity work' }
    if (element === 6) { return 'Cooking / Shopping' }
    if (element === 7) { return 'Elderly care' }
    if (element === 8) { return 'Farmstay' }
    if (element === 9) { return 'Gardening' }
    if (element === 10) { return 'General maintenance' }
    if (element === 11) { return 'Help in the house' }
    if (element === 12) { return 'Help with computers / internet' }
    if (element === 13) { return 'Help with eco project' }
    if (element === 14) { return 'Helping with tourists' }
    if (element === 15) { return 'Language practice' }
    if (element === 16) { return 'Teaching' }
    if (element === 17) { return 'Other' }
  }

  static parseAccountType = function (accountType) {
    if (accountType === 1) {
      return 'Individual'
    } else {
      return 'Couple'
    }
  }

  static convertWorkTypeNamesToIDs (workTypeNames) {
    if (workTypeNames[0] === 'Work Type' || workTypeNames[0] === 'All work types') {
      workTypeNames[0] = 0
    } else {
      for (let i = 0; i < workTypeNames.length; i++) {
        if (workTypeNames[i] === 'Animal care') { workTypeNames[i] = 1 }
        if (workTypeNames[i] === 'Art project') { workTypeNames[i] = 2 }
        if (workTypeNames[i] === 'Babysitting / Childcare') { workTypeNames[i] = 3 }
        if (workTypeNames[i] === 'Building') { workTypeNames[i] = 4 }
        if (workTypeNames[i] === 'Charity work') { workTypeNames[i] = 5 }
        if (workTypeNames[i] === 'Cooking / Shopping') { workTypeNames[i] = 6 }
        if (workTypeNames[i] === 'Elderly care') { workTypeNames[i] = 7 }
        if (workTypeNames[i] === 'Farmstay') { workTypeNames[i] = 8 }
        if (workTypeNames[i] === 'Gardening') { workTypeNames[i] = 9 }
        if (workTypeNames[i] === 'General maintenance') { workTypeNames[i] = 10 }
        if (workTypeNames[i] === 'Help in the house') { workTypeNames[i] = 11 }
        if (workTypeNames[i] === 'Help with computers / internet') { workTypeNames[i] = 12 }
        if (workTypeNames[i] === 'Help with eco project') { workTypeNames[i] = 13 }
        if (workTypeNames[i] === 'Helping with tourists') { workTypeNames[i] = 14 }
        if (workTypeNames[i] === 'Language practice') { workTypeNames[i] = 15 }
        if (workTypeNames[i] === 'Teaching' ) { workTypeNames[i] = 16 }
        if (workTypeNames[i] === 'Other') { workTypeNames[i] = 17 }
      }
    }
    return workTypeNames
  }

  static convertFilterimage (imagePath, host_id) {
    if (imagePath.substring(0, 5 === '1main')) {
      return [{
        name: imagePath,
        uri: `${sources.IMAGE_STORE_URL}/${host_id}/${imagePath}.png`,
        isSocialImage: false
      }]
    } else if (imagePath.substring(0, 2) === 'fb') {
      return [{
        name: 'facebookImage',
        uri: `https://graph.facebook.com/${imagePath.substring(2, imagePath.length)}/picture?height=500`,
        isSocialImage: true
      }]
    } else if (imagePath !== 'dummy') {
      return [{
        name: imagePath,
        uri: `${sources.IMAGE_STORE_URL}/${host_id}/${imagePath}.png`,
        isSocialImage: false
      }]
    } else {
      return [sources.DUMMY_IMAGE]
    }
  }

  static getFirstImageSource (imagesArray) {
    let imageSource
    if (imagesArray[0]) {
      if (imagesArray[0].name === 'facebookImageHidden') {
        if (imagesArray[1]) {
          imageSource = imagesArray[1].uri
        } else {
          imageSource = sources.DUMMY_IMAGE.uri
        }
      } else {
        imageSource = imagesArray[0].uri
      }
    } else {
      imageSource = sources.DUMMY_IMAGE.uri
    }
    return imageSource
  }

  static getImageName(url) {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  static getImageArray (imageArray) {
    if (imageArray) {
      if (imageArray[0]) {
        return imageArray
      } else {
        return [ sources.DUMMY_IMAGE ]
      }
    } else {
      return [ sources.DUMMY_IMAGE ]
    }
  }

  static generateTitle (userData) {
    let title = userData.username
    if (title.length > 12 && window.innerWidth < 1199) {
      title = title.substring(0, 12) + '...'
    }
    return title
  }

  static checkEmptyFields (emptyFields) {
    if (emptyFields.length === 0) {
      return true
    } else {
      // Set the header of the prompt:
      let alertString
      if (emptyFields.length === 1) { 
        alertString = 'Please fill in the following field:\n' 
      } else {
        alertString = 'Please fill in the following fields:\n' 
      }

      // Add the list of empty field to the prompt.
      emptyFields.forEach(element => {
        alertString = alertString + '- ' + element + '\n'
      })

      // Finally show the prompt and remove the last \n.
      alert(alertString.slice(0, -1))
    }
  }

  static async getDetailsRoute (userData, getHostMarkerDetailsAnyApprovalApi) {
    let id = userData.id
    if (userData.users_id) { id = userData.users_id }
    if (userData.host_id) { id = userData.host_id }

    let route
    if (userData?.user_account_type_id === 1 || userData?.user_account_type_id === 2 || userData?.detailsRoute === '/traveller') { // If it's a travel user
      route = routes.TRAVEL_USER_DETAILS + '/' + userData.slug
    } else if (userData?.host_event_count === 1 || userData?.host_id) { // If it's a host user with a hostmarker
      const hostmarkerFetch = await getHostMarkerDetailsAnyApprovalApi.request(id)
      if (!hostmarkerFetch) { return }
      
      if (hostmarkerFetch.data.status === '') {
        route = routes.HOST_MARKER_DETAILS + '/' + hostmarkerFetch.data.slug
      } else { // If the marker isn't approved then don't show its details.
        route = routes.UNSET_USER_DETAILS + '/' + userData.slug 
      }
    } else {
      // If it's a new user or a hostuser without a hostmarker
      route = routes.UNSET_USER_DETAILS + '/' + userData.slug
    }
    return route
  }

  static setGalleryImages = function (images) {
    let output = []
    for (let i = 0; i < images.length; i++) {
      output.push({
        original: images[i].uri,
        thumbnail: images[i].uri
      })
    }
    return output
  }

  static getInitialPosition = function (coordinates) {
    if (coordinates) {
      return {
        lat: coordinates[Object.keys(coordinates)[0]],
        lng: coordinates[Object.keys(coordinates)[1]]
      }
    } else {
      return {
        lat: 52.5,
        lng: 19.2
      }
    }
  }

  static setInitialHostMarkerCoordinates = function (userData) {
    if (userData.marker && userData.marker.is_travel_event === 0) {
      return {
        lat: parseFloat(userData.marker.latitude),
        lng: parseFloat(userData.marker.longitude),
      }
    } else {
      return false
    }
  }

  static setInitialTravelMarkerCoordinates = function (userData) {
    if (userData.marker && userData.marker.is_travel_event === 1) {
      return {
        lat: parseFloat(userData.marker.latitude),
        lng: parseFloat(userData.marker.longitude),
        isSavedCoordinates: true
      }
    } else {
      return false
    }
  }

  static formatDate(date) {
    const d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = d.getFullYear()
    
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    const result = [year, month, day].join('-');
    return result
  }

  static formatWorkTypeIdentifiersToString (workTypesArray) {
    // Id's:
    // { name: 'Animal care', id: 1, },
    // { name: 'Art project', id: 2, },
    // { name: 'Babysitting / Childcare', id: 3, },
    // { name: 'Building', id: 4, },
    // { name: 'Charity work', id: 5, },
    // { name: 'Cooking / Shopping', id: 6, },
    // { name: 'Elderly care', id: 7, },
    // { name: 'Farmstay', id: 8, },
    // { name: 'Gardening', id: 9, },
    // { name: 'General maintenance', id: 10, },
    // { name: 'Help in the house', id: 11, },
    // { name: 'Help with computers / internet', id: 12, },
    // { name: 'Help with eco project', id: 13, },
    // { name: 'Helping with tourists', id: 14, },
    // { name: 'Language practice', id: 15, },
    // { name: 'Teaching', id: 16, },
    // { name: 'Other', id: 17, },
    let workTypeString = '-[@]-'
    for (let i = 0; i < workTypesArray.length; i++) {
      if (workTypesArray[i] === 1) { workTypeString = workTypeString + 'wt_01' }
      if (workTypesArray[i] === 2) { workTypeString = workTypeString + 'wt_02' }
      if (workTypesArray[i] === 3) { workTypeString = workTypeString + 'wt_03' }
      if (workTypesArray[i] === 4) { workTypeString = workTypeString + 'wt_04' }
      if (workTypesArray[i] === 5) { workTypeString = workTypeString + 'wt_05' }
      if (workTypesArray[i] === 6) { workTypeString = workTypeString + 'wt_06' }
      if (workTypesArray[i] === 7) { workTypeString = workTypeString + 'wt_07' }
      if (workTypesArray[i] === 8) { workTypeString = workTypeString + 'wt_08' }
      if (workTypesArray[i] === 9) { workTypeString = workTypeString + 'wt_09' }
      if (workTypesArray[i] === 10) { workTypeString = workTypeString + 'wt_10' }
      if (workTypesArray[i] === 11) { workTypeString = workTypeString + 'wt_11' }
      if (workTypesArray[i] === 12) { workTypeString = workTypeString + 'wt_12' }
      if (workTypesArray[i] === 13) { workTypeString = workTypeString + 'wt_13' }
      if (workTypesArray[i] === 14) { workTypeString = workTypeString + 'wt_14' }
      if (workTypesArray[i] === 15) { workTypeString = workTypeString + 'wt_15' }
      if (workTypesArray[i] === 16) { workTypeString = workTypeString + 'wt_16' }
      if (workTypesArray[i] === 17) { workTypeString = workTypeString + 'wt_17' }
    }
    return workTypeString
  }

  static async getAuthStatus(userId, isAuthenticated, getProfileDetailsApi) {
    if (isAuthenticated) {
      const fetch2 = await getProfileDetailsApi.request()
      if (!fetch2) { return }
      if (userId === fetch2.data.id) {
        return 'authOwnListing'
      } else {
        return 'authOthersListing'
      }
    } else {
      return 'noAuth'
    }
  }

  static getDateTime() {
    var currentdate = new Date(); 
    var datetime = "Datetime: " + currentdate.getDate() + "/"
                    + (currentdate.getMonth()+1)  + "/" 
                    + currentdate.getFullYear() + " @ "  
                    + currentdate.getHours() + ":"  
                    + currentdate.getMinutes() + ":" 
                    + currentdate.getSeconds();
    
    return datetime
  }

  static async initPremium (getOrCreateRevenuecatCustomerInfoApi, createStripeCustomerAndSubscriptionApi) {

    let customerInfo = false;
    let platform = false;
    let clientSecret = false;

    const customerInfoFetch = await getOrCreateRevenuecatCustomerInfoApi.request();
    if (!customerInfoFetch) { return }

    // If the customer has an active subscription that has not expired.
    if (new Date(customerInfoFetch?.data?.subscriber?.entitlements?.Premium?.expires_date) > new Date()) {
      if (customerInfoFetch.data.subscriber.entitlements.Premium.product_identifier === process.env.REACT_APP_IOS_PRODUCT_IDENTIFIER) {
        platform = "ios"
      } else if (customerInfoFetch.data.subscriber.entitlements.Premium.product_identifier === process.env.REACT_APP_ANDROID_PRODUCT_IDENTIFIER) {
        platform = "android"
      } else {
        platform = "stripe"
      }
      customerInfo = customerInfoFetch
    } else { // Otherwise prepare for buying a new subscription.
      const createStripeCustomerAndSubscriptionFetch = await createStripeCustomerAndSubscriptionApi.request()
      if (!createStripeCustomerAndSubscriptionFetch) { return }
      clientSecret = createStripeCustomerAndSubscriptionFetch.data.clientSecret
    }

    return {
      customerInfo: customerInfo,
      platform: platform,
      clientSecret: clientSecret
    }
  }

  static async getAccessType(userdata, getOrCreateRevenuecatCustomerInfoApi, createStripeCustomerAndSubscriptionApi) {
    const result = {} 

    // First check for paidAccess (because a user may have paid and then became host etc.)
    // https://sdk.revenuecat.com/android/5.0.0/public/com.revenuecat.purchases/-store/index.html
    result.customerInfo = await getOrCreateRevenuecatCustomerInfoApi.request();
    if ((new Date(result.customerInfo?.data?.subscriber?.entitlements?.Premium?.expires_date) > new Date())) {
      result.type = 'paidAccess' // ok
      result.hasAccess = true
      if (result.customerInfo.data.subscriber.entitlements.Premium.product_identifier === process.env.REACT_APP_IOS_PRODUCT_IDENTIFIER) {
        result.platform = "ios"
      } else if (result.customerInfo.data.subscriber.entitlements.Premium.product_identifier === process.env.REACT_APP_ANDROID_PRODUCT_IDENTIFIER) {
        result.platform = "android"
      } else {
        result.platform = "stripe"
      }
      return result 
    } else if (userdata?.marker?.hostMarkerPosition?.is_approved) {
      result.type = 'freeAccessApprovedHostMarker' // ok
      result.hasAccess = true
      return result
    } else if (userdata.subscription_id === 'old_traveller_vip') {
      result.type = 'freeAccessOldTravellerVip' // ok
      result.hasAccess = true
      return result
    } else if (!userdata?.marker?.hostMarkerPosition?.is_approved && userdata?.host_event_count === 1) {
      result.type = 'hostmarkerAwaitingApproval' // ok
      result.hasAccess = false
      return result
    } else {
      result.type = 'noAccess' // ok
      result.hasAccess = false
      const createStripeCustomerAndSubscriptionFetch = await createStripeCustomerAndSubscriptionApi.request()
      if (!createStripeCustomerAndSubscriptionFetch) { return }
      result.clientSecret = createStripeCustomerAndSubscriptionFetch.data.clientSecret
      return result;
    }
  }

  static generateAIHippoAvatar(conversationID) {
    const lastDigit = conversationID % 10;

    if (lastDigit === 0 || lastDigit === 5) {
      return '/images/ai-hippo-1.png'
    } else if (lastDigit === 1 || lastDigit === 6) {
      return '/images/ai-hippo-2.png'
    } else if (lastDigit === 2 || lastDigit === 7) {
      return '/images/ai-hippo-3.png'
    } else if (lastDigit === 3 || lastDigit === 8) {
      return '/images/ai-hippo-4.png'
    } else if (lastDigit === 4 || lastDigit === 9) {
      return '/images/ai-hippo-5.png'
    }
  }

  static checkIfOnlyDigits(value) {
    const regex = /^\d+$/;
    return regex.test(value);
  }
}