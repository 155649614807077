import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import Divider from '../components/formatting/Divider';
import Footer from '../components/formatting/Footer';
import HTMLContent from '../variables/HTMLContent';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import HTMLDialog from '../components/dialogs/HTMLDialog'

function InformationForHippohostsScreen() {
  const { loginWithRedirect } = useAuth0();
  const [getAppDialogOpen, setGetAppDialogOpen] = useState(false)

  const handleLogin = function (nextScreen) {
    if (window.innerWidth < 1199) {
      setGetAppDialogOpen(true)
    } else {
      if (nextScreen === "signup") {
        loginWithRedirect({ screen_hint: 'signup' })
      } else {
        loginWithRedirect()
      }
    }
  }

  return (
    <>   
      <HTMLDialog 
        title="Get the app!"
        content="getApp"
        passedOpen={getAppDialogOpen} 
        onClose={() => {setGetAppDialogOpen(false)}}
        data={{
          authActions: true
        }}
      />
      <HelmetProvider>
        <Helmet>
          <title>Hippohelp | Information for Hippohosts</title>
          <meta name="description" content="Find more information on what it means to be a host at Hippohelp." />
        </Helmet>
      </HelmetProvider>
      <Box className='contentBox'>
        <Typography component="h1" variant="h4">Information For Hippohosts</Typography>
        <Divider />
        <div className='leftText'>
          <HTMLContent content="hostInfo" />
        </div>
        <Button onClick={() => handleLogin("signup")} className={"linearButton"}>Join the community</Button>
      </Box>
      <Footer />
    </>
  )
}

export default InformationForHippohostsScreen