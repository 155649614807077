import React, { useState } from "react"
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom"
import HTMLDialog from "../dialogs/HTMLDialog";
import { useAuth0 } from "@auth0/auth0-react";

function NavBarLoggedOut () {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
  const history = useHistory();
  const [getAppDialogOpen, setGetAppDialogOpen] = useState(false)

  const handleLogin = function (nextScreen) {
    if (window.innerWidth < 1199) {
      setGetAppDialogOpen(true)
    } else {
      if (nextScreen === "signup") {
        loginWithRedirect({ screen_hint: 'signup' })
      } else {
        loginWithRedirect()
      }
    }
  }

  return (
    <>
      <HTMLDialog 
        title="Get the app!"
        content="getApp"
        passedOpen={getAppDialogOpen} 
        onClose={() => {setGetAppDialogOpen(false)}}
        data={{
          authActions: true
        }}
      />
      <div className={"d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 border-bottom hippoNavbar hippoNavbarStart"}>
        <div className="navBarWrapper">
          <img 
            srcSet="../images/head-80x80.png 80w, ../images/logo.png,"
            sizes="(max-width: 500px) 80px"
            src="../images/logo.png"
            className="hippoLogo" 
            onClick={() => history.push('/')}
          />
        </div>
        <div className="navBarWrapper">
          {/* <Button className={"getAppButtonLoggedOut displayNone"} variant="outlined">Get the app</Button> */}
          <Button onClick={() => handleLogin("login")} className={"genericButton secondaryColor"}>Login</Button>
          <Button onClick={() => handleLogin("signup")} className={"genericButton joinButton secondaryColor"}>Join</Button>
          <Button className={"getAppButtonLoggedOut"} variant="outlined" onClick={() => setGetAppDialogOpen(true)}>Get the app</Button>
        </div>
      </div>
    </>
  )
}

export default NavBarLoggedOut
