import React, { useState, useEffect } from 'react';
import FaqTabs from '../components/other/FaqTabs/FaqTabs'
import Button from '@material-ui/core/Button';
import * as importedJson from '../variables/licenses.json';
import Text from '../components/other/Text'
import { useAuth0 } from "@auth0/auth0-react";
import Divider from '../components/formatting/Divider.js'
import Box from '@mui/material/Box';

const HTMLContent = function ({content, action, data}) {
  const { loginWithRedirect } = useAuth0();
  if (content === 'safety') {
    return (
      <>
        <p>Please note: While we work to keep the platform free from any malicious users we don't screen each and every user. It's up to you to ensure that you're fine meeting up or living with another user.</p>
        
        <p>Meeting new people from all over the world will lead to adventures and interactions never to be forgotten, but when meeting new people, especially from other cultures it's important to have a few things in mind to ensure a great experience for both the host and the traveller!</p>
        
        <p>For both travellers and hosts:</p>
        <ul>
          <li> Make sure the host's or traveller's profile is complete with a real name, detailed description and clear photos. Also look at what others have written in the reviews section.</li>
          <li> Make sure that the move-in/move-out date, tasks, working-schedule, eating and sleeping arrangement etc. are clear for both the host and the traveller in advance.</li>
          <li> Make sure that you have written clear "house rules" if you're a host, and that you fully understand them if you are a traveller.</li>
          <li> Get to know the person before meeting using the built-in messaging system, or a video-chat app like Skype. Be sure to ask a lot of questions, in the rare cases things don't work out it is usually because the expectations of either party weren't met due to lack of communication. Here's a few questions to ask in advance:</li>
          <ul>
            <li> How many people live in your home?</li>
            <li> How many travellers have you hosted, or how many hosts have you helped?</li>
            <li> Am I allowed to use the kitchen?</li>
            <li> Are there any safety precautions I should take before my stay?</li>
          </ul>
          <li> Spend some time to get to know each other when you finally meet in person. If anything makes you uncomfortable, of if you feel like something is not like the description, be sure to bring it up in a friendly fashion.</li>
          <li> If you feel threatened or in immediate danger, immediately remove yourself and contact the local authorities, such as police or emergency services. When you're safe, please report the incident to us.</li>
          <li> Any arrangements are between the host and traveller, we only posts the listings and help with the initial contact between hosts and travellers.</li>
          <li> If you are a woman you might need to give more consideration on how, where and when you travel, or who you choose to host.</li>
          <li> Make sure to understand all the cultural codes of the country where the host or traveller is from.</li>
        </ul>
        <p>For travellers:</p>
        <ul>
          <li>Don't travel without a travel insurance.</li>
          <li> Traveling can be exhausting and disorienting, use of drugs and alcohol will further complicate things. Make sure that you always have control of yourself, don't leave your well-being in the hands of someone else.</li>
          <li> Is the area your going to safe for travelers? What is the current political climate?</li>
          <li> Always have a backup-plan. What happens if the hosts changes the arrangements, or if you don't have cell-phone coverage. Always let your close ones know your travel-route, and where you will be staying.</li>
        </ul>
      </>
    )
  }

  if (content === 'getApp') {
    return (
      <>
        <div className="getAppContent">
          <p>The mobile version of Hippohelp is best viewed on our iOS and Android apps!</p>
          
          <div className='getAppDoubleBadge'>
            <a href='https://play.google.com/store/apps/details?id=com.lhuber92.hippohelpreactnative' target="_blank">
              <img 
                className='getAppBadge'
                alt='Get it on Google Play'
                src='../images/androidapp500w.png'
              />
            </a>
            <a href='https://apps.apple.com/app/hippohelp/id1640438077' target="_blank">
              <img 
                className='getAppBadge'
                alt='Download on the App Store'
                src='../images/iosapp500w.png'
              />
            </a>
          </div>

          {data?.authActions && (
            <>
            <Divider />
            <div>
              <p style={{margin: 0}}>Use web-app instead:</p>
              <div>
                <Button onClick={() => loginWithRedirect()} className={"genericButton secondaryColor unsetWidth"}>Login</Button>
                <Button onClick={() => loginWithRedirect({ screen_hint: 'signup' })} className={"genericButton joinButton secondaryColor unsetWidth"}>Join</Button>
              </div>
            </div>
            </>
          )}

        </div>

      </>
    )
  }

  if (content === 'mapLogin') {
    return (
      <>
        <div className="getAppContent">
          <p>Create a free account to browse all hosts and travellers on Hippohelp!</p>
          
          <div className='getAppDoubleBadge' style={{marginBottom: "4px"}}>
            <Button onClick={() => loginWithRedirect({ screen_hint: 'signup' })} className={"linearButton"}>Join the community</Button>
          </div>

          <Divider />
          
          <div style={{marginTop: "-16px"}}>
            <p >Already have an account? <span className='hippoLink hippoLinkOtherColor' onClick={() => loginWithRedirect()}>Login</span></p>
          </div>

        </div>

      </>
    )
  }

  if (content === 'termsAndConditions') {
    return (
      <>
        <p>Introduction</p>
        <p>
          Hippohelp is a platform connecting helpers, also known as travellers, with locals, also known as hosts. With Hippohelp travellers are able to provide free services for the hosts, in exchange for free food and housing from the host during the visit. Hosts and Travellers can find each other using the platform. The platform is accessible at the website www.hippohelp.com or with the official Hippohelp app (available on Apple's "App Store" and Google's "Google Play") and run by the Swedish company Shimmershot AB.
        </p>
        <p>
          By using the website you agree to comply with these Terms and Conditions, you also acknowledge that in case you would take any legal action against users of the website, or any third parties, you will not claim any liability against Hippohelp.
        </p>
        <p>Definitions:</p>
        <ul>
          <li>Member: A person using Hippohelp.com</li>
          <li>Host: A member who need help with a project from helpers</li>
          <li>Traveller: A member who is not a host. A traveller many times provide services for the Host</li>
          <li>Platform or website: Hippohelp.com</li>
        </ul>
        <p>Company information:</p>
        <ul>
          <li>Name of company: Shimmershot AB</li>
          <li>Country of company registration: Sweden</li>
          <li>Address of company: Adelgatan 7A, 393 50 Kalmar, Sweden</li>
          <li>Organisation number of company: 559282-3594</li>
        </ul>
        <p>Disclaimer</p>
        <ul>
          <li>Hippohelp does not warrant any information or pictures provided by members. Pictures and information provided by members are the responsibility of the members.</li>
          <li>The website may include links to third party sites. They are only there for your convenience, and Hippohelp does not take any responsibility of damages occured by visiting third party sites.</li>
          <li>Hippohelp excludes all liability for any damage caused as a result of using Hippohelp.</li>
          <li>Hippohelp is not a party to any arrangements between the host and the traveller, and does not screen hosts or travellers.</li>
        </ul>
        <p>Age:</p>
        <ul><li>By using the website or services you warrant that you are over 18 years old. Any use of the website by anyone under 18 is prohibited. </li></ul>
        <p>Creating and deleting an account:</p>
        <ul>
          <li>An account is created by clicking the "Register" or "Continue with Facebook" button</li>
          <li>An account is deleted by pressing the delete button from the account section of the website.</li>
        </ul>
        <p>By using the website you agree with the following:</p>
        <ul>
          <li>You are responsible of your accounts security, and will not give your account details to any other person.</li>
          <li>All information you enter on the website will be correct.</li>
          <li>All photos you upload will represent you as a member.</li>
          <li>You have permission to live and host travellers in the facility where you reside.</li>
          <li>You are using Hippohelp entirely at your own risk.</li>
          <li>You are solely responsible for ensuring that any person you accept is who they say they are.</li>
          <li>You are solely responsible for complying with the relevant rules and laws where you host travellers, or where you travel as a traveller.</li>
          <li>You are soley responsible for finding a host or a traveller.</li>
          <li>If your account is terminated because of a breach of our terms you will not be entitled to any refunds. Hippohelp reserves the right to terminate any account without prior notice.</li>
          <li>You grant Hippohelp a non-exclusive, transferable, sub-licensable, royalty free, worldwide license to use any photos uploaded from your account to the platform in marketing purposes in connection with Hippohelp.</li>
          <li>You have read the safety guidelines on the website, and understand that Hippohelp is not in any way resposible for the safety of the hosts or travellers.</li>
        </ul>
        <p>By using the website you agree to not do any of the following:</p>
        <ul><li>Violate these terms, any law or regulation, or any order of a court.
        Use the website or services for illegal purposes or in order to harm Hippohelp in any way.
        Collect or store any data on platform with any other purpose other than using the service.
        Register more than one account on the website.
        Access in any way any unauthorized web pages.
        Encourage or assist any third party in doing any of the foregoing. </li></ul>
        <p>Objectionable content and behaviors:</p>
        <p>Objectionable content and behaviors refer to any material, information, or actions that are deemed inappropriate or offensive by a reasonable person. This includes but is not limited to:</p>
        <ul>
          <li>Content that promotes or glorifies violence, hate speech, discrimination, terrorism, harassment, bullying, or any other form of aggression towards individuals or groups based on their race, ethnicity, religion, gender, sexual orientation, age, disability, or any other characteristic.</li>
          <li>Pornographic or sexually explicit content, including depictions of nudity or sexual acts that are not suitable for minors.</li>
          <li>Content that infringes upon the intellectual property rights of others, such as trademarks, copyrights, patents, or trade secrets.</li>
          <li>Malicious or harmful activities, such as impersonation, phishing, spamming, hacking, or spreading malware or viruses.</li>
          <li>Illegal activities, such as promoting or facilitating drug use, gambling, or other illegal behavior.</li>
          <li>Contnet that is insensitive toward a sensitive event with significant social, cultural, or political impact, such as civil emergencies, natural disasters, public health emergencies, conflicts, deaths, or other tragic events.</li>
          <li>Content aimed at marketing of firearms or other dangerous products, or instructions on how to manufacture those.</li>
          <li>Content aimed at marketing or selling marijuana or tobacco and alcohol.</li>
          <li>Content related to planning, preparing or glorifying voilence against civilians.</li>
        </ul>
        <p>We strictly prohibit any use of our app that involves objectionable content or behaviors as defined above. Users who violate these terms may be subject to account suspension or termination, and we reserve the right to take legal action if necessary.</p>
        <p>Child Endangerment</p>
        <p>Hippohelp prohibits the use of the app to endanger children. This includes but is not limited to the use of the app to promote predatory behavior towards children, such as:</p>
        <ul>
          <li>Inappropriate interaction targeted at a child (for example, groping or caressing).</li>
          <li>Child grooming (for example, befriending a child online to facilitate, either online or offline, sexual contact and/or exchanging sexual imagery with that child).</li>
          <li>Sexualization of a minor (for example, imagery that depicts, encourages or promotes the sexual abuse of children or the portrayal of children in a manner that could result in the sexual exploitation of children).</li>
          <li>Sextortion (for example, threatening or blackmailing a child by using real or alleged access to a child's intimate images).</li>
          <li>Trafficking of a child (for example, advertising or solicitation of a child for commercial sexual exploitation).</li>
        </ul>
        <p>We take these matters very seriously and will report any content with child sexual abuse materials to the appropriate authorities. If you believe that a child is in danger of or has been subject to abuse, exploitation, or trafficking, please contact your local law enforcement agency immediately.</p>
        <p>Hippohelp reserves the right to do the following, at any time:</p>
        <ol type="1">
          <li>Disable access to the website because of a breach of the terms.</li>
          <li>Delete your user account because we, for whatever reason, do not see you as a fit member of the Hippohelp community.</li>
          <li>Investigate and prosecute any violator of the terms.</li>
          <li>Disclose any information, if required to do so by law.</li>
        </ol>
        <p>Payments and Subscriptions</p>
        <ul>
          <li>Payments for subscriptions are not refundable.</li>
          <li>Subscriptions are automatically renewed if not canceled by the customer. The customer can cancel a subscription anytime from the "Account" section of Hippohelp.</li>
        </ul>
        <p>Contact us</p>
        <ul><li>You can contact Hippohelp by sending a message from the contact section accessible both from the logged-in and logged-out section of the website.</li></ul>
        <p>About these terms</p>
        <ul>
          <li>Hippohelp may modify any of the terms at any given time, and you agree to be bound by any changes made by us. It is your responsibility to verify any changes of the terms.</li>
          <li>These terms are governed by Swedish law. Any disputes which might arise between you and Hippohelp will be heard in Swedish courts, unless both you and Hippohelp agree otherwise.</li>
        </ul>
      </>
    )
  }

  if (content === 'privacyPolicy') {
    return (
      <>
      <p>By using Hippohelp (www.hippohelp.com), which is run by the Swedish company Shimmershot AB, you consent to the collection and use of your data as described in this Privacy Policy. Hippohelp may modify any of the terms at any given time, you agree to be bound by any changes made by us, and that it is your responsibility to verify any changes of the terms. You can remove your consent to this policy by deleting your Hippohelp account and not enter www.hippohelp.com.</p>
      <p>We collect this only when users provide it to us:</p>
      <ul>
        <li>Basic user information, such as username, email and current location</li>
        <li>Sent and received messages</li>
        <li>Background information, such as your occupation and age.</li>
        <li>Information stored on a mobile device, such as photos, if you have given us permission to retrieve this information.</li>
        <li>Information provided by other users, such as ratings and connections with other members.</li>
        <li>Information associated with Facebook account, such as username, email and Facebook ID will be stored if you choose to register on Hippohelp using your Facebook account. Your Facebook profile photo will also be displayed on Hippohelp.com</li>
      </ul>
      <p>This is collected automatically:</p>
      <ul>
        <li>Location information.</li>
        <li>Information about what kind of device you are using.</li>
        <li>Logged information, such as IP-address and time of access.</li>
        <li>Information collected by cookies and other tracking technologies. This is in order to improve our service and the user experience, monitor user activity, count visits and information on marketing campaign effectiveness.</li>
      </ul>
      <p>Why we use the collected data:</p>
      <p>We use the data for the purposes explained in this privacy policy, or otherwise disclosed to you when using our services. Some use-cases might include:</p>
      <ul>
        <li>Compare or combine with other data collected from third parties to improve our service to you as a user.</li>
        <li>Protect the safety of you and other users.</li>
        <li>Operate and improve our services.</li>
        <li>Send you alert messages.</li>
        <li>Provide customer service.</li>
        <li>Communicate new products and rewards to our users.</li>
        <li>Monitor and analyze user-trends.</li>
        <li>Making it possible for other users to contact you and see the location you've entered on Hippohelp.com.</li>
      </ul>
      <p>Conditions when we share information:</p>
      <ul>
        <li>When you consent to information sharing.</li>
        <li>When outside parties need your information in order to carry out their work for us.</li>
        <li>When information is being shared to other users of the site.</li>
        <li>When search indexes is indexing our site.</li>
        <li>When our partners run contests and events with a connection to Hippohelp.</li>
        <li>In connection or during a merger with another company, or transactions with another company involving assets or financing.</li>
        <li>If we believe that disclosure is reasonably necessary in order to comply with any applicable laws and regulations.</li>
        <li>When we need to enforce our agreements, policies and terms of use, or to prevent damages, harm or illegal activities.</li>
        <li>When the data is aggregated or de-identifies information, which cannot reasonably be used to identify you.</li>
        <li>When you use third party and social media tools in connection with Hippohelp. For more information relating to the collection of these providers you need to visit the privacy policies of the third parties that provide these features.</li>
      </ul>
      <p>Links to third party sites:</p>
      <p>The site may include links to third party sites. They are only there for your convenience, and Hippohelp does not take any responsibility of damages occurred by visiting third party sites. Third party sites might collect information about you, but Hippohelp have no responsibility or liability regarding the information provided by these sites.</p>
      <p>Analytics and advertising services provided by third parties:</p>
      <p>Third-party providers of analytics and advertising services might use cookies, web beacons, and other technologies to collect information about your activities on other websites. This information might include time spent on pages, your browser's operating system and pages viewed. Hippohelp, and others, may use this information in order to analyse traffic and monitor ad-campaigns. The Google Analytics features that are implemented on the Hippohelp website are: Google Analytics 4 (GA4), Google Search Console, Google Tag Manager and Google signals for Google Analytics 4 properties. To opt out from data being collected by Google Analytics when visiting the website you can enable the Google Analytics Opt-out Browser Add-on. More information about this can be found here: https://tools.google.com/dlpage/gaoptout/. You can see and manage the details of the data that Google has collected about you under 'My Google Activity'. More information about this can be found here: https://myactivity.google.com/myactivity.</p>
      
      <p>Hippohelp may share aggregated, non-personal information, or personal information in a non-human readable form, with third parties in order to analyse our traffic and monitor ad-campaigns. For example, we might ask a third party provider to view ads to you based on what pages you have viewed on our site. For more information about interested-based-ads, and how to opt out of having your web browsing information used for behavioural marketing purposes, please visit https://themeisle.com/blog/what-are-tracking-cookies</p>
      
      <p>How we protect your information:</p>
      <p>Hippohelp takes reasonably measures in order to protect your information from loss, theft, unauthorised access, misuse, disclosure, destruction or alteration.</p>
      
      <p>How long time we save your information:</p>
      <p>Hippohelp will save information manually entered by you for as long as you have an active account on the platform.</p>
      
      <p>Where your data is stored:</p>
      <p>Your data is stored on servers located in different countries worldwide. These servers are rented by Hippohelp or rented or owned by third parties who use your data.</p>
      
      <p>Account information:</p>
      <p>You may at any time update your account information by logging into your account and editing your profile. From here you will also be able to deactivate  your account. Hippohelp will only provide other members with account data that is already available as a logged in user.</p>
      
      <p>Rights to Erasure:</p>
      <p>You may at any time delete your account and personal information that you have provided us with by going to the "Account" section once logged in and pressing the "Delete Account" button. Even if you delete your account we may retain some information if we are required by law, for legitimate business purposes, or to protect the safety of other members. If your account is deactivated Hippohelp will provide access to the data associated with your account upon receipt of the required legal documentation.</p>
      
      <p>Location information from mobile devices:</p>
      <p>You have the ability to disable and enable location services by adjusting the settings in your internet browser, doing so might mean that you cannot access all or some of Hippohelp's services.</p>
      
      <p>Cookies:</p>
      <p>Most web browser's today are set to accept cookies by default. You can change this setting, but the procedure varies from browser to browser. To find out how to do it on your browser, please look in your browser's help menu, or visit http://www.whatarecookies.com/enable.asp. Please note that our services might not work properly if cookies are disabled.</p>
      
      <p>Promotional messages from us</p>
      <p>You might opt out from receiving promotional messages from us by following our instructions in the promotional emails we send, or by adjusting the appropriate settings in your profile. If you opt out we might still send you messages relating to your account.</p>
      
      <p>Contact us:</p>
      <p>If you have any questions then please contact us using the form on the frontpage of www.hippohelp.com, you can find it by clicking the "more" button if you're logged in, or the "contact us" link if you aren't.</p>
     </>
    )
  }

  if (content === "hostInfo") {
    return (
      <>
        <p>Hippohelpers are looking for an experience different to what they are used to in their own country, some are students taking a gap-year, some are professionals taking a career break, some are families with kids and some are retirees.</p>

        <p>This means that they should be part of your family, team or community, and that you as a host should interact with them as much as possible. As a host you should also be interested in cultural exchange, and should provide accommodation and food for the travellers.</p>

        <p>In exchange you'll meet people from all over the world who are happy to help with your projects. The offered help is usually 5 hours per day, 5 days a week, but this is something you and the traveller decide together.</p>

        <p>Hippohosts running a business for profit should also offer monetary payments on top equal to at least the minimum wage in the country where the host is located. This can be offset by the food and accommodation on offer.</p>

        <Box className='dualBox infoDualBox'>
          <Box>
            <img className='staticImage' src="../images/informationForHippohosts/1.png"/>
          </Box>
          <Box>
            <img className='staticImage' src="../images/informationForHippohosts/2.png"/>
          </Box>
        </Box>

        <p>Some examples of fun and giving exchanges are eco-farms, language teaching, animal keeping, working as a crew member on a yacht, baby sitting, ecobuilding, cooking and general maintenance work. These are just a few examples, the list of interesting projects you can post on Hippohelp is endless.</p>

        <p>As a host you'll place a hostmarker indicating your location on the map, once we've approved your marker it will be public to all users. Our registered users can contact you directly using our built in message system (your email address is never visible), and you'll get notifications to your mailbox on important events, such as when you receive a message or a friend request.</p>

        <p>It's entirely up to you to offer or accept volunteers, we only help travellers and hosts finding each other.</p>

        <p>You can also see travellers currently in your area, and reach out to them directly. You can find travellers by looking after orange travelmarkers on the map.</p>

        <p>For more information, please take a look at our safety and FAQ sections, you'll find them by pressing the "More" button.</p>
      </>
    )
  }

  if (content === "travellerInfo") {
    return(
      <>
        
        <p>Using Hippohelp as a traveller you'll open up a world of possibilities. Be it immersing yourself to new languages and cultures, saving on your travels or beefing up your CV, Hippohelp can help you out.</p>

        <p>By working in exchange for food and accommodation you'll be more than just a tourist. You'll be able to live like the locals and bond with your host.</p>

        <p>The average hippohelper provides around 5 hours of work per day, 5 days per week, in exchange for food and board.</p>

        <p>Meaningful projects on Hippohelp include eco-farms, babysitting, eco-construction, general maintenance and language teaching. These are just a few examples, the list of interesting projects you can join on Hippohelp is endless.</p>

        <Box className='dualBox infoDualBox'>
          <Box>
            <img className='staticImage' src="../images/informationForHippohelpers/1.png"/>
          </Box>
          <Box>
            <img className='staticImage' src="../images/informationForHippohelpers/2.png"/>
          </Box>
        </Box>

        <p>Please note that Hippohelping is much more than just working in exchange for food and accommodation. Your hosts will most likely be interested in getting to know you, so try to not be shy and make sure to spend some quality time together with your host. Inviting travellers and teaching them the daily tasks takes a lot of planning and effort for the hosts, so try to be as helpful as possible during your stay.</p>

        <p>One more thing to note is that Hippohelp is not an agency, we only enable you to find hosts and cannot guarantee that you get any placements. It's up to you to make contact with the host, discuss the arrangements and make sure that the stay at your host will be a pleasant one.</p>

        <p>Once you've signed up you'll be able to create your profile and start browse hosts in the area you plan on going to. You can also place a travelmarker on the map, indicating where you are or plan on going to. This is a great way of making yourself more visible for travellers who are in your area, and might want to meet up or share a ride.</p>

        <p>For more information, please take a look at our safety and FAQ sections, you'll find them by pressing the "More" button.</p>
      </>
    )
  }

  if (content === "faq") {
    return (
      
      <div>
        <FaqTabs />
      </div>
    )
  }

  if (content === "deleteAccount") {
    return(
      <>
        <div className='deleteAccountContent'>
          <p>Warning! This action cannot be undone.</p>

          <Button 
            className={"genericButton"} 
            onClick={action}
          >
              Delete Account
          </Button>
        </div>
      </>
    )
  }

  if (content === "deleteAccountCancelFirst") {
    return(
      <>
        <div className='deleteAccountContent'>
          <p>You still have an active subscription, please cancel it from the premium screen and come back here later (it may take a few hours for the cancel to register).</p>
          <p>Contact us <a href='/contact'>here</a> if you have any issues.</p>
          <Button 
            className={"genericButton"} 
            onClick={action}
          >
              Premium screen
          </Button>
        </div>
      </>
    )
  }

  if (content === "resetAccount") {
    return(
      <>
        <div className='resetAccountContent'>
          <p>Press the button below to remove all your public details and markers. Images, messages, favourites, friends or other data will not be removed.</p>

          <p>Warning! This action cannot be undone.</p>

          <Button 
            className={"genericButton"} 
            onClick={action}
          >
              Reset Public Details
          </Button>
        </div>
      </>
    )
  }

  if (content === 'licenses') {
    const data = importedJson.default
    const allKeys = Object.keys(data)

    return (
      <>
        <p style={{textAlign: 'center'}}>Many thanks to the contributors of the following packages!</p>
        {
          allKeys.map(function (currentLicense, index) {
            if (currentLicense !== 'default') {
              const currentObject = data[currentLicense]
              if (currentObject.licenseUrl) {
                return (
                  <div className='licenseBox' key={index}>
                    <Text>Name of package:</Text>
                    <Text>{currentLicense}</Text>
                    <Text></Text>
                    <Text>URL to license:</Text>
                    <Text>{currentObject.licenseUrl}</Text>
                  </div>
                )
              }
            }
          })
        }
      </>
    )
  }
}

export default HTMLContent
