import React, { useContext, useState, useEffect } from 'react'
import useApi from '../../hooks/useApi'
import userApi from '../../api/userApi'
import Button from '@material-ui/core/Button';
import Spinner from '../other/Spinner';

function Cancel ({customerInfo, platform}) {
  const cancelStripeSubscriptionApi = useApi(userApi.cancelStripeSubscription)
  const [loading, setLoading] = useState(false);

  const cancelSubscription = async function () {
    if (platform === "ios" || platform === "android") {
      window.open(customerInfo.data.subscriber.management_url, '_blank');
      location.href='/'
    } else {
      setLoading(true)
      const fetch = await cancelStripeSubscriptionApi.request()
      if (!fetch) { return }
      setTimeout(() => {
        return location.href='/'
      }, 5000)
    }
  }

  return (
    <div>
      <Spinner visible={loading} />
      {!loading && (
        <div className={"cancelPrompt"}>
          <p>
            Cancel Subscription
          </p>
          <p>
            Press the button below to cancel your Hippohelp Premium Subscription.
          </p>
          <p>
            It may take a few hours until your cancelation is detected by the app (you don't need to press the cancel button twice).
          </p>
          <p>
            If you're having any issues with canceling your subscription, please press <a href='/contact'>here</a> to contact us. Be sure to describe exactly what happened when you tried to perform the cancellation.
          </p>
          <p>
            Changed your mind? Go <a href='/'>home</a> instead!
          </p>
          <div>
            <Button style={{marginTop: 20}} className={"genericButton"} onClick={() => cancelSubscription()}>Cancel subscription</Button>
          </div>
        </div>
      )}
    </div>
  )
}

const localStyles = {
  background: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  buttonsContainer: {
    padding: "20px",
    width: '100%',
    bottom: "20px"
  },
  logo: {
    width: "300px",
    height: "300px",
    position: 'absolute',
    top: "30px"
  },
  registerButton: {
    width: '100%',
    height: "70px",
    position: 'absolute'
  }
}

export default Cancel
