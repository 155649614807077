import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import Divider from '../components/formatting/Divider';
import Footer from '../components/formatting/Footer';
import HTMLContent from '../variables/HTMLContent';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import HTMLDialog from '../components/dialogs/HTMLDialog'
import useApi from '../hooks/useApi';
import blogApi from '../api/blogApi';
import CategorySection from "../components/blog/CategorySection.js"
import CategoryPath from "../components/blog/CategoryPath.js"
import ReactMarkdown from "react-markdown";
import Text from "../components/other/Text"

const generateCategories = (blogPostData) => {
  return {
    category_name: blogPostData.category_name,
    category_slug: blogPostData.category_slug,
    sub_category_name: blogPostData.sub_category_name,
    sub_category_slug: blogPostData.sub_category_slug,
    sub_sub_category_name: blogPostData.sub_sub_category_name,
    sub_sub_category_slug: blogPostData.sub_sub_category_slug
  }
}

function BlogPostScreen(props) {
  const { loginWithRedirect } = useAuth0();
  const [getAppDialogOpen, setGetAppDialogOpen] = useState(false)
  const getCategoriesApi = useApi(blogApi.getCategories)
  const getBlogPostApi = useApi(blogApi.getBlogPostBySlug)
  const [blogPost, setBlogPost] = useState(false)
  const [categories, setCategories] = useState(false)

  const handleLogin = function (nextScreen) {
    if (window.innerWidth < 1199) {
      setGetAppDialogOpen(true)
    } else {
      if (nextScreen === "signup") {
        loginWithRedirect({ screen_hint: 'signup' })
      } else {
        loginWithRedirect()
      }
    }
  }

  useEffect(() => {
    async function fetchMyApi() {
      const postFetch = await getBlogPostApi.request(props.match.params.slug)
      if (!postFetch) { return }

      const categoryFetch = await getCategoriesApi.request()
      if (!categoryFetch) { return }

      setCategories(categoryFetch.data)
      setBlogPost(postFetch.data)
    }
    
    fetchMyApi()
  }, [])

  const renderers = {
    img: (image) => {
      return (
        <img 
          src={image.src} 
          alt={image.alt} 
          style={{ 
            maxHeight: "500px",
          }} 
        />
      );
    },
    a: ({ href, children }) => {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    },
    ul: ({ node, ...props }) => <ul style={{ listStyleType: "disc", paddingLeft: "1em" }} {...props} />,
  };

  const formatDate = (isoDate) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const date = new Date(isoDate);
    return date.toLocaleString(undefined, options);
  }
  return (
    <>   
      <HTMLDialog 
        title="Get the app!"
        content="getApp"
        passedOpen={getAppDialogOpen} 
        onClose={() => {setGetAppDialogOpen(false)}}
        data={{
          authActions: true
        }}
      />
      {blogPost && (
        <HelmetProvider>
          <Helmet>
            <title>{blogPost.title} | Hippohelp Blog</title>
            <meta name="description" content={blogPost.snippet} />
          </Helmet>
        </HelmetProvider>
      )}
      <Box className='contentBox' minHeight={"400px"}>
        {blogPost && categories && (
          <Box>
            <Box className="pathAndCategory">
              <Box>
                <CategoryPath currentCategory={generateCategories(blogPost)} />
              </Box>
              <Box>
                <CategorySection categories={categories} showTopLevelCategories />
              </Box>
            </Box>
            <Box display={"flex"} width={"100%"} justifyContent={"center"}>
              <Box p={4} className="blogPostContent">
                {new Date(blogPost.created_at) > new Date() && (
                  <h2 style={{
                    color: "red",
                  }}>
                    Post will be live: {formatDate(blogPost.created_at)}
                  </h2>
                )}
                {console.log(blogPost)}
                <Typography style={{marginTop: "40px", marginBottom: "40px"}} component="h1" variant="h3">{blogPost.title}</Typography>
                <ReactMarkdown components={renderers}>
                  {blogPost.content}
                </ReactMarkdown>
                <Box width={"100%"}>
                  <p 
                    style={{margin: 0, padding: 0, textAlign: "left", width: "100%"}}
                  >
                    Created at: {blogPost.created_at.split('T')[0]}
                  </p>      
                  <p 
                    style={{margin: 0, padding: 0, textAlign: "left", width: "100%"}}
                  >
                    Updated at: {blogPost.created_at.split('T')[0]}
                  </p>               
                  <p 
                    style={{margin: 0, padding: 0, textAlign: "left", width: "100%"}}
                  >
                    Author: {blogPost.author}
                  </p>
                </Box>
                <Divider />
                <Button onClick={() => handleLogin("signup")} className={"linearButton"}>Join the community</Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Footer />
    </>
  )
}

export default BlogPostScreen