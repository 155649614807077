import React, { useState, useEffect } from 'react';
import useApi from '../hooks/useApi'
import userApi from '../api/userApi'
import Paywall from '../components/premium/Paywall';
import Cancel from '../components/premium/Cancel';
import { Elements } from "@stripe/react-stripe-js";
import { Util } from '../utils/util';
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

function PremiumScreen ({title = "Hippohelp Premium"}) {
  const [access, setAccess] = useState(false)
  const getProfileDetailsApi = useApi(userApi.getProfileDetails)
  const getOrCreateRevenuecatCustomerInfoApi = useApi(userApi.getOrCreateRevenuecatSubscriber)
  const createStripeCustomerAndSubscriptionApi = useApi(userApi.createStripeCustomerAndSubscription)

  const fetchData = async () => {
    const fetch = await getProfileDetailsApi.request()
    if (!fetch) { return }
    setAccess(await Util.getAccessType(fetch.data, getOrCreateRevenuecatCustomerInfoApi, createStripeCustomerAndSubscriptionApi))
  };

  useEffect(() => {
    fetchData()
  }, []);

  return (
      <>
        {access && (
          <>
            {access.type === 'freeAccessApprovedHostMarker' && (
              <div style={{textAlign: 'center', padding: '20px'}}>
                <p>You have premium features with no need to pay, since you are a host!</p>
                <a href='/'>Home</a>
              </div>
            )}
            {access.type === 'freeAccessOldTravellerVip' && (
              <div style={{textAlign: 'center', padding: '20px'}}>
                <p>You have premium features with no need to pay, since you are an old user!</p>
                <a href='/'>Home</a>
              </div>
            )}
            {access.type === 'hostmarkerAwaitingApproval' && (
              <div style={{textAlign: 'center', padding: '20px'}}>
                <p>Once your hostmarker is approved you will have premium access for free, come back here then!</p>
                <a href='/'>Home</a>
              </div>
            )}
            {access.type === 'paidAccess' && (
              <Cancel customerInfo={access.customerInfo.data} platform={access.platform} />
            )}
            {access.type === 'noAccess' && (
              <Elements options={{ clientSecret: access.clientSecret, appearance: { theme: 'stripe' } }} stripe={stripePromise}>
                <Paywall title={title} />
              </Elements>
            )}
          </>
        )}
      </>
  )
}

export default PremiumScreen;